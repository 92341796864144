import React from 'react';
import { Breadcrumbs as BaseBreadcrumbs, Link } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import { FormattedMessage } from 'react-intl';
import { breadcrumbsStyle } from './BreadcrumbsStyle';
import { Link as GatsbyLink } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(breadcrumbsStyle);

export const Breadcrumbs: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <BaseBreadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
      <Link component={GatsbyLink} to="/" className={classes.link}>
        <HomeIcon className={classes.icon} />
        <FormattedMessage id="breadcrumbs.home" defaultMessage="Home" />
      </Link>
      {children}
    </BaseBreadcrumbs>
  );
};
