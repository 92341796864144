import { LudsCoordinates } from './geoTypes';

const degToRad = (deg: number) => deg * (Math.PI / 180);

const getLatitude = (point: LudsCoordinates): number => {
  return Number(point.lat);
};

const getLongitude = (point: LudsCoordinates): number => {
  return Number(point.lon);
};

// See https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates
// See https://www.movable-type.co.uk/scripts/latlong.html
const getDistanceFromLudsLocationTo = (toLocation: LudsCoordinates, inKilometers = true): number => {
  const fromLat = 51.99547498183513;
  const fromLon = 5.682260640628842;

  const toLat = getLatitude(toLocation);
  const toLon = getLongitude(toLocation);

  const R = 6371e3; // Radius of the earth in metres
  const deltaLat = degToRad(toLat - fromLat);
  const deltaLon = degToRad(toLon - fromLon);

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(degToRad(fromLat)) * Math.cos(degToRad(toLat)) * Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c; // distance in metres

  return inKilometers ? Math.round(distance) / 1000 : distance;
};

export { degToRad, getLatitude, getLongitude, getDistanceFromLudsLocationTo };
