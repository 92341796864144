import { ShoppingCartProducts } from '../store/reducers/CartReducer';
import { ProductNode } from '../interfaces/contentful';
import { getDistanceFromLudsLocationTo } from '../geo/geoHelpers';

export const calculateAmount = (product: ProductNode & { quantity: number }): number => {
  return (product.price * 100 * product.quantity) / 100;
};

export const calculateTotalAmount = (products: ShoppingCartProducts): number => {
  return (
    Object.values(products).reduce((acc, product) => {
      const price = product.price;
      return acc + price * 100 * product.quantity;
    }, 0) / 100
  );
};

/**
 * If you ever need to change the delivery price algorithm, remember to do it also in Lambda
 */
export const calculateTotalDeliveryAmount = (products: ShoppingCartProducts): number => {
  const FIXED_DELIVERY_PRICE_IN_CENTS = 250;
  const DYNAMIC_DELIVERY_PRICE_PERCENTAGE_OF_TOTAL_ORDER_PRICE = 0.05;

  const distances: number[] = [];
  Object.values(products).forEach((product) => {
    const distance = getDistanceFromLudsLocationTo(product.suppliedBy.location);
    distances.push(distance);
  });

  const totalAmountInCents = calculateTotalAmount(products) * 100;

  // If someone orders only one type of product (so 4 x vanilla yoghurt is fine), give him the distance based delivery price discount
  if (distances.length === 1) {
    let LOCATION_BASED_PRICE_IN_CENTS = FIXED_DELIVERY_PRICE_IN_CENTS;

    if (distances[0] < 5) {
      LOCATION_BASED_PRICE_IN_CENTS = 50; // 0.50 EUR if product within 5 km from LUDS
    } else if (distances[0] < 10) {
      LOCATION_BASED_PRICE_IN_CENTS = 100; // 1.00 EUR if product between 5km-10km from LUDS
    } else if (distances[0] < 15) {
      LOCATION_BASED_PRICE_IN_CENTS = 200; // 2.00 EUR if product between 10km - 15km from LUDS
    }

    const distanceBasedDeliveryPrice =
      LOCATION_BASED_PRICE_IN_CENTS + totalAmountInCents * DYNAMIC_DELIVERY_PRICE_PERCENTAGE_OF_TOTAL_ORDER_PRICE;

    return distanceBasedDeliveryPrice / 100;
  }

  return (
    (FIXED_DELIVERY_PRICE_IN_CENTS + totalAmountInCents * DYNAMIC_DELIVERY_PRICE_PERCENTAGE_OF_TOTAL_ORDER_PRICE) / 100
  );
};

export const calculateTotalAmountWithDelivery = (products: ShoppingCartProducts): number => {
  const totalAmountInCents = calculateTotalAmount(products) * 100;
  const deliveryAmountInCents = calculateTotalDeliveryAmount(products) * 100;

  return (totalAmountInCents + deliveryAmountInCents) / 100;
};
