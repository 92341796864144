import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import typographyStyle from '../../assets/jss/typographyStyle';

const useStyles = makeStyles(typographyStyle);

export const Danger: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.defaultFontStyle + ' ' + classes.dangerText}>{children}</div>;
};
