import {
  section,
  container,
  main,
  mainRaised,
  grayColor,
  breadcrumbs,
  defaultFont,
  dangerColor,
  gridItem,
} from '../materialKitBase';

import { createStyles } from '@material-ui/core/styles';

export const cartStyle = (theme: any) =>
  createStyles({
    gridItem,
    imgContainer: {
      width: '120px',
      maxHeight: '160px',
      overflow: 'hidden',
      display: 'block',
      '& img': {
        width: '100%',
      },
    },
    tableCellTotal: {
      fontWeight: 500,
      fontSize: '1.0625rem',
      paddingTop: '20px',
      textAlign: 'right',
      // [theme.breakpoints.down('sm')]: {
      //   textAlign: 'left',
      // },
    },
    tableCellDeliveryOption: {
      lineHeight: '41px',
    },
    breadcrumbs,
    main,
    mainRaised,
    container: {
      ...container,
    },
    section: {
      ...section,
      padding: '70px 0px',
    },
    icon: {
      marginRight: '5px',
      width: 20,
      height: 20,
    },
    link: {
      display: 'flex',
    },
    parallax: {
      height: '70vh',
      overflow: 'hidden',
    },
    cartPage: {
      '& $mainRaised': {
        margin: '-40vh 0 0',
        padding: '40px',
        // margin: '2vh 0 0',
        // padding: '20px',
      },
      backgroundColor: grayColor[2],
    },
    price: {
      fontSize: '18px',
      color: grayColor[22],
    },
    priceOld: {
      fontSize: '16px',
      textDecoration: 'line-through',
    },
    priceNew: {
      color: dangerColor[0],
    },
    formControl: {
      margin: '10px',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    field: {
      marginBottom: '10px',
    },
    // See more https://material-ui.com/customization/breakpoints/
    legalPrivacyCheckbox: {
      [theme.breakpoints.only('xs')]: {
        marginTop: '15px',
      },
    },
  });
