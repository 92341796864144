import React, { Fragment, useContext } from 'react';
import {
  ButtonGroup,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Link as GatsbyLink } from 'gatsby';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { cartStyle } from '../../assets/jss/pages/cartStyle';
import { GatsbyImage } from 'gatsby-plugin-image';
import { formatPrice } from '../../helpers/formatterHelper';
import { Button } from '../Button/Button';
import Remove from '@material-ui/icons/Remove';
import Add from '@material-ui/icons/Add';
import {
  calculateAmount,
  calculateTotalDeliveryAmount,
  calculateTotalAmount,
  calculateTotalAmountWithDelivery,
} from '../../helpers/pricingHelper';
import { Close } from '@material-ui/icons';
import { ShoppingCartProducts } from '../../store/reducers/CartReducer';
import { CartDispatchContext } from '../../store/StateProvider';
import { FormattedMessage } from 'react-intl';
import { grayColor } from '../../assets/jss/materialKitBase';
import { getDistanceFromLudsLocationTo } from '../../geo/geoHelpers';

interface ProductsInCartBlockProps {
  numberOfProducts: number;
  products: ShoppingCartProducts;
}

const useStyles = makeStyles(cartStyle);

export const ProductsInCartTableBlock: React.FC<ProductsInCartBlockProps> = ({ numberOfProducts, products }) => {
  const classes = useStyles();
  const dispatch = useContext(CartDispatchContext);
  const theme = useTheme();
  const matchesMobileScreens = useMediaQuery(theme.breakpoints.down('xs'));

  if (numberOfProducts <= 0) return null;

  return (
    <Fragment>
      <Typography variant="h5" component="h2" gutterBottom>
        <FormattedMessage id="cart.products" defaultMessage="Products" />
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          {!matchesMobileScreens && (
            <TableHead>
              <TableRow style={{ textTransform: 'uppercase' }}>
                <TableCell align="left" style={{ width: '120px' }}>
                  <FormattedMessage id="cart.products.image" defaultMessage="Image" />
                </TableCell>
                <TableCell align="left" style={{ width: '278px' }}>
                  <FormattedMessage id="cart.products.product" defaultMessage="Product" />
                </TableCell>
                <TableCell align="right" style={{ width: '150px' }}>
                  <FormattedMessage id="cart.products.price" defaultMessage="Price" />
                </TableCell>
                <TableCell align="right" style={{ width: '220px' }}>
                  <FormattedMessage id="cart.products.quantity" defaultMessage="QTY" />
                </TableCell>
                <TableCell align="right" style={{ width: '150px' }}>
                  <FormattedMessage id="cart.products.amount" defaultMessage="Amount" />
                </TableCell>
                <TableCell style={{ width: '80px' }}>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {matchesMobileScreens &&
              Object.values(products).map((product) => (
                <TableRow key={product.contentful_id}>
                  <TableCell>
                    <div className={classes.imgContainer}>
                      <GatsbyImage
                        alt={product.coverImage.title ? product.coverImage.title : ''}
                        image={product.coverImage.gatsbyImageData}
                      />
                    </div>

                    <Link
                      component={GatsbyLink}
                      to={`/products/${product.slug}`}
                      className={classes.link}
                      style={{ marginBottom: '-5px' }}
                    >
                      {product.title}
                    </Link>
                    <span style={{ fontSize: '10px' }}>
                      {product.suppliedBy.name}
                      <br />
                    </span>
                    <span style={{ fontSize: '10px' }}>
                      {product.unit}: {product.unitValue},{' '}
                      <FormattedMessage
                        id="cart.products.distance"
                        defaultMessage="Distance: {distance} km"
                        values={{ distance: getDistanceFromLudsLocationTo(product.suppliedBy.location).toFixed(2) }}
                      />
                    </span>

                    <br />
                    <br />

                    <span className={classes.price}>
                      <FormattedMessage id="cart.products.price" defaultMessage="Price" />
                      :&nbsp;{formatPrice(product.price)}
                    </span>

                    <br />

                    <div className={classes.price}>
                      <FormattedMessage id="cart.products.quantity" defaultMessage="QTY" />
                      :&nbsp;{product.quantity}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                      <ButtonGroup color="primary" aria-label="outlined primary button group">
                        <Button
                          color="info"
                          size="sm"
                          round
                          onClick={(): void =>
                            dispatch({
                              type: 'cart/removeFromCart',
                              payload: {
                                newProduct: product,
                                quantity: 1,
                              },
                            })
                          }
                        >
                          <Remove />
                        </Button>
                        <Button
                          color="info"
                          size="sm"
                          round
                          onClick={(): void =>
                            dispatch({
                              type: 'cart/addToCart',
                              payload: {
                                newProduct: product,
                                quantity: 1,
                              },
                            })
                          }
                        >
                          <Add />
                        </Button>
                      </ButtonGroup>
                    </div>

                    <span className={classes.price}>
                      <FormattedMessage id="cart.products.amount" defaultMessage="Amount" />
                      :&nbsp;
                      {formatPrice(calculateAmount(product))}
                    </span>
                  </TableCell>
                </TableRow>
              ))}

            {!matchesMobileScreens &&
              Object.values(products).map((product) => (
                <TableRow key={product.contentful_id}>
                  <TableCell>
                    <div className={classes.imgContainer}>
                      <GatsbyImage
                        alt={product.coverImage.title ? product.coverImage.title : ''}
                        image={product.coverImage.gatsbyImageData}
                      />
                    </div>
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <Link component={GatsbyLink} to={`/products/${product.slug}`} className={classes.link}>
                      {product.title}
                    </Link>
                    <span style={{ fontSize: '10px' }}>
                      {product.suppliedBy.name}
                      <br />
                    </span>
                    <span style={{ fontSize: '10px' }}>
                      {product.unit}: {product.unitValue},{' '}
                      <FormattedMessage
                        id="cart.products.distance"
                        defaultMessage="Distance: {distance} km"
                        values={{ distance: getDistanceFromLudsLocationTo(product.suppliedBy.location).toFixed(2) }}
                      />
                    </span>
                  </TableCell>
                  <TableCell align="right">
                    <span className={classes.price}>{formatPrice(product.price)}</span>
                  </TableCell>
                  <TableCell align="right">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <ButtonGroup color="primary" aria-label="outlined primary button group">
                        <Button
                          color="info"
                          size="sm"
                          round
                          onClick={(): void =>
                            dispatch({
                              type: 'cart/removeFromCart',
                              payload: {
                                newProduct: product,
                                quantity: 1,
                              },
                            })
                          }
                        >
                          <Remove />
                        </Button>
                        <Button
                          color="info"
                          size="sm"
                          round
                          onClick={(): void =>
                            dispatch({
                              type: 'cart/addToCart',
                              payload: {
                                newProduct: product,
                                quantity: 1,
                              },
                            })
                          }
                        >
                          <Add />
                        </Button>
                      </ButtonGroup>
                      <div style={{ marginLeft: '5px' }}>{product.quantity}</div>
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    <span className={classes.price}>{formatPrice(calculateAmount(product))}</span>
                  </TableCell>
                  <TableCell>
                    <Button
                      link
                      style={{ margin: 0, padding: '5px' }}
                      onClick={(): void =>
                        dispatch({
                          type: 'cart/removeFromCart',
                          payload: {
                            newProduct: product,
                            quantity: product.quantity,
                          },
                        })
                      }
                    >
                      <Close />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            {matchesMobileScreens && (
              <>
                <TableRow>
                  <TableCell
                    className={classes.tableCellTotal}
                    colSpan={6}
                    style={{ textTransform: 'uppercase', textAlign: 'left' }}
                  >
                    <FormattedMessage id="cart.products.totalPriceArticles" defaultMessage="Total price articles" />
                    <br />
                    {formatPrice(calculateTotalAmount(products))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className={classes.tableCellTotal}
                    colSpan={6}
                    style={{ textTransform: 'uppercase', textAlign: 'left' }}
                  >
                    <div style={{ marginBottom: '-12px' }}>
                      <FormattedMessage id="cart.shipping.deliveryCosts" defaultMessage="Delivery costs" />
                    </div>
                    <span style={{ fontSize: '8px', textTransform: 'lowercase', color: grayColor[22] }}>
                      (€2.50 vaste prijs of afstand afhankelijk + 5% van totaal artikelen)
                    </span>
                    <br />
                    {formatPrice(calculateTotalDeliveryAmount(products))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className={classes.tableCellTotal}
                    colSpan={6}
                    style={{ textTransform: 'uppercase', textAlign: 'left' }}
                  >
                    <FormattedMessage id="cart.products.totalPrice" defaultMessage="Total price" />
                    <br />
                    {formatPrice(calculateTotalAmountWithDelivery(products))}
                  </TableCell>
                </TableRow>
              </>
            )}

            {!matchesMobileScreens && (
              <>
                <TableRow>
                  <TableCell className={classes.tableCellTotal} colSpan={4} style={{ textTransform: 'uppercase' }}>
                    <FormattedMessage id="cart.products.totalPriceArticles" defaultMessage="Total price articles" />
                    <br />
                    <FormattedMessage id="cart.shipping.deliveryCosts" defaultMessage="Delivery costs" />
                  </TableCell>
                  <TableCell align="right" className={classes.tableCellTotal}>
                    {formatPrice(calculateTotalAmount(products))}
                    <br />
                    <span
                      style={{ cursor: 'help' }}
                      title="€2.50 vaste prijs of afstand afhankelijk + 5% van totaal artikelen"
                    >
                      {formatPrice(calculateTotalDeliveryAmount(products))}
                    </span>
                  </TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableCellTotal} colSpan={4} style={{ textTransform: 'uppercase' }}>
                    <FormattedMessage id="cart.products.totalPrice" defaultMessage="Total price" />
                  </TableCell>
                  <TableCell align="right" className={classes.tableCellTotal}>
                    {formatPrice(calculateTotalAmountWithDelivery(products))}
                  </TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};
