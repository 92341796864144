import React, { useContext, useState } from 'react';
import { CartDispatchContext, CartStateContext } from '../store/StateProvider';
import { makeStyles } from '@material-ui/core/styles';
import { graphql, navigate, PageRendererProps } from 'gatsby';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Grid,
  Checkbox,
} from '@material-ui/core';
import { Button } from '../components/Button/Button';
import { DefaultHeader } from '../components/Header/Header';
import { cartStyle } from '../assets/jss/pages/cartStyle';
import { Parallax } from '../components/Parallax/Parallax';
import classNames from 'classnames';
import { calculateTotalNumber } from '../helpers/productsHelper';
import { EmptyCartBlock } from '../components/ShoppingCart/EmptyCartBlock';
import { ProductsInCartTableBlock } from '../components/ShoppingCart/ProductsInCartTableBlock';
import { ShoppingCartProducts } from '../store/reducers/CartReducer';
import { FormattedMessage, useIntl } from 'react-intl';
import { Breadcrumbs } from '../components/Breadcrumbs/Breadcrumbs';
import { Helmet } from 'react-helmet';
import { Danger } from '../components/Typography/Danger';
import { CategoryNode } from '../interfaces/contentful';
import { createCategoryTree } from '../helpers/treeHelper';
import backgroundImage from '../assets/img/bg9.jpg';

export interface UserDetails {
  deliveryDays: 'Maandag' | 'Woensdag' | 'Vrijdag' | null;
  deliveryHours: '12:00-13:59' | '14:00-15:59' | '16:00-17:59' | '18:00-19:59' | null;
  email: string;
  address: string;
  description: string;
}

const useStyles = makeStyles(cartStyle);

const Cart: React.FC<CartPageProps> = ({ data }) => {
  const { categories } = data;
  const { products } = useContext(CartStateContext);
  const dispatch = useContext(CartDispatchContext);

  const categoryTree = createCategoryTree(categories);

  const intl = useIntl();
  const classes = useStyles();
  const [userDetails, setUserDetails] = React.useState<UserDetails>({
    deliveryDays: null,
    deliveryHours: null,
    email: '',
    address: '',
    description: '',
  });
  const [termsApproved, setTermsApproved] = React.useState(false);
  const [privacyRead, setPrivacyRead] = React.useState(false);

  const [ordering, setOrdering] = useState(false);
  const [orderedError, setOrderedError] = useState('');
  const [orderedSuccessfully, setOrderedSuccessfully] = useState(false);

  const numberOfProducts = calculateTotalNumber(products);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const id = event.target.id;
    const value = (event.target as HTMLInputElement).value;

    setUserDetails({
      ...userDetails,
      [id]: value,
    });
  };

  const handleTermsApprovedChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTermsApproved(event.target.checked);
  };

  const handlePrivacyReadChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPrivacyRead(event.target.checked);
  };

  /**
   * @see Some W3C Recommended standards https://www.w3.org/TR/?title=5.&tag=html&status=rec
   *
   * @see Current HTML 5.2 W3C Recommendation, 14 December 2017  https://www.w3.org/TR/2017/REC-html52-20171214/sec-forms.html#email-state-typeemail, https://html.spec.whatwg.org/#email-state-(type=email)
   *  A valid e-mail address is a string that matches the email production of the following ABNF, the character set for which is Unicode.
   *  This ABNF implements the extensions described in RFC 1123. [ABNF] [RFC5322] [RFC1034] [RFC1123]
   *
   *  The following JavaScript- and Perl-compatible regular expression is an implementation of the above definition:
   *  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
   *
   * @see Future HTML 5.3 W3C standard (in 12-2020 still Working Draft): https://www.w3.org/TR/2018/WD-html53-20181018/sec-forms.html#email-state-typeemail
   * -  A valid e-mail address is defined in [RFC6531]: https://tools.ietf.org/html/rfc6531
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#Basic_validation
   *  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
   *
   *
   * Hmm...https://tools.ietf.org/html/rfc822, https://tools.ietf.org/html/rfc2821
   */
  const areUserDetailsValid = () =>
    userDetails.email !== '' &&
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
      userDetails.email,
    ) &&
    userDetails.address !== '' &&
    userDetails.deliveryDays !== null &&
    userDetails.deliveryHours !== null;

  /**
   *
   * @param event
   * @param userDetails
   * @param products
   */
  const placeOrder = async (
    event: React.FormEvent<HTMLFormElement>,
    userDetails: UserDetails,
    products: ShoppingCartProducts,
  ): Promise<void> => {
    event.preventDefault();
    setOrdering(true);

    const preparedProducts = Object.values(products).map((product) => {
      return {
        id: product.contentful_id,
        slug: product.slug,
        name: product.title,
        price: product.price,
        quantity: product.quantity,
        suppliedByName: product.suppliedBy.name, // added just for clarity
      };
    });

    // Remove all keys with falsy values
    const preparedUserDetails = Object.entries(userDetails).reduce((acc, [key, val]) => {
      return val ? { ...acc, [key]: val } : acc;
    }, {});

    const data = {
      userDetails: preparedUserDetails,
      products: preparedProducts,
    };

    // console.log('Sending', data);

    const options: RequestInit = {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.GATSBY_ORDER_API_KEY!,
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    };

    try {
      const response = await fetch(process.env.GATSBY_ORDER_API_URL!, options);
      const responseData = await response.json();
      // console.log(responseData);
      setOrderedError('');
      dispatch({ type: 'cart/clearCart' });
      setOrderedSuccessfully(true);
      setOrdering(false);
      await navigate('/thankyou');
    } catch (err) {
      console.error(err.message);
      setOrderedError(err.message);
    } finally {
      setOrdering(false);
    }
  };

  return (
    <div className={classes.cartPage}>
      <Helmet>
        <title>{intl.formatMessage({ id: 'breadcrumbs.cart' })}</title>
      </Helmet>
      <DefaultHeader categoryTree={categoryTree} />

      <Parallax image={backgroundImage} className={classes.parallax}>
        <div className={classes.container}>
          <Breadcrumbs>
            <Typography color="secondary">
              <FormattedMessage id="breadcrumbs.cart" defaultMessage="Cart" />
            </Typography>
          </Breadcrumbs>
        </div>
      </Parallax>

      <div className={classNames(classes.section)}>
        <div className={classes.container}>
          <div className={classNames(classes.main, classes.mainRaised)}>
            {!orderedSuccessfully && <EmptyCartBlock numberOfProducts={numberOfProducts} />}
            <ProductsInCartTableBlock numberOfProducts={numberOfProducts} products={products} />

            {numberOfProducts > 0 && (
              <form
                className={classes.form}
                autoComplete="off"
                onSubmit={(form): Promise<void> => placeOrder(form, userDetails, products)}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <br />
                    <Typography variant="h5" component="h2" gutterBottom>
                      <FormattedMessage id="cart.deliveryDetails" defaultMessage="Delivery details" />
                    </Typography>
                    <TextField
                      fullWidth
                      type="email"
                      name="luds_email"
                      value={userDetails.email}
                      required
                      id="email"
                      label={<FormattedMessage id="cart.deliveryDetails.email" defaultMessage="Email" />}
                      variant="outlined"
                      className={classes.field}
                      helperText={
                        <FormattedMessage
                          id="cart.deliveryDetails.email.helperText"
                          defaultMessage="You will receive the order confirmation email on this email."
                        />
                      }
                      onChange={handleChange}
                      disabled={ordering}
                    />
                    <TextField
                      fullWidth
                      type="text"
                      name="luds_address"
                      value={userDetails.address}
                      required
                      id="address"
                      label={<FormattedMessage id="cart.deliveryDetails.address" defaultMessage="Address" />}
                      variant="outlined"
                      className={classes.field}
                      helperText={
                        <FormattedMessage
                          id="cart.deliveryDetails.address.helperText"
                          defaultMessage="Please provide street, street number, city, postal number and country"
                        />
                      }
                      onChange={handleChange}
                      disabled={ordering}
                    />
                    <FormControl component="fieldset" className={classes.formControl} disabled={ordering}>
                      <FormLabel component="legend">
                        <FormattedMessage id="cart.shipping.deliveryDays" defaultMessage="Day of delivery" /> {' *'}
                      </FormLabel>
                      <RadioGroup
                        style={{ flexDirection: 'row' }}
                        aria-label="delivery days"
                        name="luds_delivery_days"
                        value={userDetails.deliveryDays}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="Dinsdag"
                          control={<Radio id="deliveryDays" required />}
                          label={<FormattedMessage id="general.tuesday" defaultMessage="Tuesday" />}
                        />
                        <FormControlLabel
                          value="Donderdag"
                          control={<Radio id="deliveryDays" required />}
                          label={<FormattedMessage id="general.thursday" defaultMessage="Thursday" />}
                        />
                        <FormControlLabel
                          value="Zaterdag"
                          control={<Radio id="deliveryDays" required />}
                          label={<FormattedMessage id="general.saturday" defaultMessage="Saturday" />}
                        />
                      </RadioGroup>
                      {/*<span style={{ fontSize: '10px', color: grayColor[22] }}>*/}
                      {/*  (tijdelijk, in verband met de drukte en andere werkzaamheden, Liever uit de Streek functioneert*/}
                      {/*  een dag per week)*/}
                      {/*</span>*/}
                    </FormControl>
                    <br />
                    <FormControl component="fieldset" className={classes.formControl} disabled={ordering}>
                      <FormLabel component="legend">
                        <FormattedMessage id="cart.shipping.deliveryHours" defaultMessage="Time of delivery" />
                        {' *'}
                      </FormLabel>
                      <RadioGroup
                        style={{ flexDirection: 'row' }}
                        aria-label="delivery hours"
                        name="luds_delivery_hours"
                        value={userDetails.deliveryHours}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="12:00-13:59"
                          control={<Radio id="deliveryHours" required />}
                          label="12:00—13:59"
                        />
                        <FormControlLabel
                          value="14:00-15:59"
                          control={<Radio id="deliveryHours" required />}
                          label="14:00—15:59"
                        />
                        <FormControlLabel
                          value="16:00-17:59"
                          control={<Radio id="deliveryHours" required />}
                          label="16:00—17:59"
                        />
                        <FormControlLabel
                          value="18:00-19:59"
                          control={<Radio id="deliveryHours" required />}
                          label="18:00—19:59"
                        />
                      </RadioGroup>
                    </FormControl>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      id="description"
                      name="cb_description"
                      label={
                        <FormattedMessage
                          id="cart.deliveryDetails.orderDescription"
                          defaultMessage="Order description"
                        />
                      }
                      variant="outlined"
                      className={classes.field}
                      onChange={handleChange}
                      disabled={ordering}
                    />
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            required
                            disabled={ordering}
                            color="primary"
                            checked={termsApproved}
                            onChange={handleTermsApprovedChange}
                          />
                        }
                        label={
                          <span>
                            Ik ga akkoord met de{' '}
                            <a target="_blank" href="/legal/algemene-voorwaarden">
                              <FormattedMessage id="legal.generalConditions" defaultMessage="General Conditions" />
                            </a>
                            {' *'}
                          </span>
                        }
                        labelPlacement="end"
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        className={classes.legalPrivacyCheckbox}
                        control={
                          <Checkbox
                            required
                            disabled={ordering}
                            color="primary"
                            checked={privacyRead}
                            onChange={handlePrivacyReadChange}
                          />
                        }
                        label={
                          <span>
                            Ik heb de{' '}
                            <a target="_blank" href="/legal/privacy-verklaring">
                              <FormattedMessage id="legal.privacyStatement" defaultMessage="Privacy statement" />
                            </a>
                            {' gelezen en ga akkoord met het verwerken van mijn persoonsgegevens *'}
                          </span>
                        }
                        labelPlacement="end"
                      />
                    </div>
                    <div>
                      <Button
                        type="submit"
                        color="info"
                        disabled={ordering || !areUserDetailsValid() || !termsApproved || !privacyRead}
                      >
                        <FormattedMessage id="cart.placeOrder" defaultMessage="Place order" />
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </form>
            )}

            {numberOfProducts > 0 && orderedError.length > 0 && (
              <Danger>
                <FormattedMessage
                  id="cart.placeOrder.error"
                  defaultMessage="Problem with ordering. Contact us or try again later on."
                />
              </Danger>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Cart;

export const cartQuery = graphql`
  query {
    categories: allContentfulCategory {
      edges {
        node {
          slug
          title
          contentful_id
          parentCategory {
            slug
            contentful_id
          }
          regionalproduct {
            contentful_id
          }
        }
      }
    }
  }
`;

interface CartPageProps extends PageRendererProps {
  data: {
    categories: {
      edges: { node: CategoryNode }[];
    };
  };
}
