import { breadcrumbs } from '../../assets/jss/materialKitBase';
import { createStyles, Theme } from '@material-ui/core';

export const breadcrumbsStyle = (theme: Theme) =>
  createStyles({
    breadcrumbs,
    link: {
      display: 'flex',
    },
    icon: {
      marginRight: '5px',
      width: 20,
      height: 20,
    },
  });
