import React from 'react';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

interface EmptyCartBlockProps {
  numberOfProducts: number;
}

export const EmptyCartBlock: React.FC<EmptyCartBlockProps> = ({ numberOfProducts }) => {
  if (numberOfProducts > 0) return null;

  return (
    <div>
      <Typography variant="h5" component="h2" gutterBottom>
        <FormattedMessage id="cart.empty.title" defaultMessage="Cart is empty" />
      </Typography>
      <p>
        <FormattedMessage id="cart.empty.text1" defaultMessage="Please check our featured or latest products." />
      </p>
    </div>
  );
};
